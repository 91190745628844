import React, { useState, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import qs from "qs";
import { Row, Container, Col, ToggleButtonGroup, ToggleButton, Form } from 'react-bootstrap'
import Layout from '../components/Layout'
import PeopleListView from '../components/PeopleList/PeopleListView'
import SearchInput from '../components/SearchInput'
import PeopleGridView from '../components/PeopleList/PeopleGridView'
import BtnLink from '../components/Common/StyledButton/BtnLink'
import SwitchComponents from '../components/SwitchComponents'
import Pagination from '../components/Pagination'
import Filters from '../components/Filters'
import Seo from 'gatsby-plugin-wpgraphql-seo';
import {
	listPeople,
	listPeopleHeader,
	listPeopleForm,
	displayOptions,
	displayLabels,
	displayLabelActive,
	paginationWrapper
} from './ListPeople.module.scss'
import { usePeopleSearch } from "../hooks/use-people-search";

const PeoplePage = ({
	location,
	data,
	pageContext: {
		nextPagePath,
		previousPagePath,
		currPage,
		archivePath,
		archiveTitle,
		numPages,
		offset,
		postsPerPage,
		totalItems,
	},
}) => {
	const loaded = useRef(false);
	const searchInput = useRef(false)

	// check for activecomponent
	let view
	if (location?.state?.activeComponent) {
		view = location.state.activeComponent
	}
	const people = data.allWpPeople.edges
	const [peopleResults, setPeopleResults] = useState(people)
	const peopleSearch = usePeopleSearch();

	const [activeComponent, setActiveComponent] = useState(view ?? 'PeopleGridView')

	//filters
	const queryParams = qs.parse(location.search.replace('?', ''))
	const [searchTerm, setSearchTerm] = useState(queryParams?.s ?? null)
	const [filtered, setFiltered] = useState(false)
	const [searched, setSearched] = useState(!!searchTerm)
	const [filterState, setFilterState] = useState({
		office: null,
		language: null,
		service: null,
		sector: null,
	})

	const handleViewChange = val => {
		setActiveComponent(val)
	}

	const handleClearFilter = e => {
		setSearchTerm(null)
		searchInput.current.value = ''
		setFilterState({
			office: null,
			language: null,
			service: null,
			sector: null,
		})
	}


	useEffect(() => {
		// if page is loaded with a search term
		if (!loaded.current) {
			if (searchTerm) {
				handleSubmit()
			}
		}
	}, [peopleResults, filterState]);

	useEffect(() => {
		filterResults()
	}, [filterState])

	const filterResults = () => {
		// prevent double filtering on page load
		if (!loaded.current) {
			loaded.current = true;
			return
		}

		let peopleList = peopleSearch.people
		let sectorPeople = []
		let servicePeople = []

		if (filterState.sector) {
			sectorPeople = peopleList?.filter(({ people }) => people?.peopleInformation?.proService?.map(service => service?.slug).includes(filterState.sector))
		}

		if (filterState.service) {
			servicePeople = peopleList?.filter(({ people }) => people?.peopleInformation?.proService?.map(service => service?.slug).includes(filterState.service))
		}

		if (filterState.sector || filterState.service) {
			peopleList = [...sectorPeople, ...servicePeople]

			peopleList = new Set(peopleList)
			peopleList = Array.from(peopleList)
			peopleList.sort((a, b) => (a.people.peopleInformation.lastName > b.people.peopleInformation.lastName) ? 1 : -1)
		}

		if (filterState.language) {
			const languageObj = peopleSearch.languages.find(({ language }) => language.slug === filterState.language)
			const peopleByLanguage = languageObj.language.allpeople.nodes.map(obj => obj.id)
			peopleList = peopleList.filter(({ people }) => peopleByLanguage.includes(people.id))
		}

		if (filterState.office) {
			peopleList = peopleList?.filter(({ people }) => people?.peopleInformation?.proOffice?.map(office => office?.slug).includes(filterState.office))
		}

		// no filters and no search terms, just reset
		if (!filterState.service && !filterState.sector && !filterState.office && !filterState.language && !searchTerm) {
			search(people)
			setFiltered(false)
			setSearched(false)
			return
		}

		search(peopleList)
		setFiltered(true)
	}

	const handleInputChange = e => {
		setSearchTerm(e.target.value)
	}

	const handleSubmit = e => {
		e?.preventDefault();
		filterResults()
	}

	const search = (peopleList) => {
		if (!searchTerm) {
			setPeopleResults(peopleList)
			setSearched(false)
			return
		}
		const res = peopleList.filter(({ people }) => {
			const term = searchTerm.toLowerCase()
			const termSplit = term.split(' ')
			const name = people.title.toLowerCase()
			const service = people?.peopleInformation?.proService?.map(service => service?.title).join(' ').toLowerCase()
			const language = people?.languages?.nodes?.map(language => language?.name).join(' ').toLowerCase()
			const location = people?.peopleInformation?.proOffice?.map(office => `${office?.title} ${office.officeInformation.proOfficeCity}`).join(' ').toLowerCase()
			const summary = people?.peopleContent?.summary?.replace(/<[^>]+>/g, '').toLowerCase()
			const school = people?.peopleContent?.education?.replace(/<[^>]+>/g, '').toLowerCase()
			const repMatters = people?.peopleContent?.repMatters?.replace(/<[^>]+>/g, '').toLowerCase()

			const nameMatch = termSplit.every(v => name.includes(v))

			return nameMatch || service?.includes(term) || language?.includes(term) || location?.includes(term) || school?.includes(term) || summary?.includes(term) || repMatters?.includes(term)
		});

		setPeopleResults(res)
		setSearched(true)
	}

	const filterCount = Object.values(filterState).filter(x => x !== '' && x !== null).length
	const metaDesc = 'All the attorneys at Procopio are knowledgable business lawyers with years of experience and able to handle the most complex cases in their practice field.'

	return (
		<Layout>
			<Seo post={{ seo: {
					title: `People | Page ${currPage}`,
					metaDesc: metaDesc,
					opengraphDescription: metaDesc,
				}
			}} />
			<Container fluid="lg">
				<header className={listPeopleHeader}>
					<Row>
						<Col className=" mb-3 mb-sm-0" xs={12} sm={6} lg={4}>
							<h1 className="title--xl title--decor">
								Find a <i>Professional</i>
							</h1>
						</Col>
						<Col xs={12} sm={6} lg={{span: 6, offset: 2}}>
							<div className="d-flex justify-content-end">
								<div className="w-100">
									<Form onSubmit={handleSubmit} className={listPeopleForm}>
										<SearchInput
											defaultValue={searchTerm}
											value={searchTerm}
											placeholderText="Search by name, service, sector or school"
											aria-label="Search"
											onInputChange={handleInputChange}
											size="sm"
											iconSize='sm'
											onBtnClick={handleSubmit}
											innerRef={searchInput}
										/>
									</Form>
								</div>
								<div className="d-none d-md-block">
									<Filters
										languages={peopleSearch.languages}
										offices={peopleSearch.offices}
										setFilterState={setFilterState}
										filterState={filterState}
										filterCount={filterCount}
									/>
								</div>
							</div>
						</Col>
					</Row>
				</header>

				<hr />

				<Row>
					<Col xs={6} sm={9}>
						{filtered &&
							<div className="mt-2">
								<BtnLink onClick={handleClearFilter} useButton={true} cta="Clear Filters" />
							</div>
						}
					</Col>
					<Col xs={6} sm={3}>
						<div className={`custom-controls ` + displayOptions}>			
							<ToggleButtonGroup
								type="radio"
								toggle="true"
								aria-label="Display Professionals by grid or list"
								name="listView"
								onChange={handleViewChange}
								defaultValue={activeComponent}
							>
								<ToggleButton
									id="grid-view"
									className={`${displayLabels} ${activeComponent == 'PeopleGridView' ? displayLabelActive : ''
										}`}
									value="PeopleGridView"
									tabIndex="-1"
								>
									<span>
										<i className="ss-icon ss-geomicons-squared ss-grid" /> Grid
									</span>
								</ToggleButton>

								<ToggleButton id="list-view" tabIndex="-1" className={`${displayLabels} ${activeComponent == 'PeopleListView' ? displayLabelActive : ''
									}`} value="PeopleListView">
									<span>
										<i className="ss-icon ss-geomicons-squared ss-list" /> List
									</span>
								</ToggleButton>
							</ToggleButtonGroup>
						</div>
					</Col>
				</Row>
				<Row>
					<Col>
						<hr />
					</Col>
				</Row>
				<section className={listPeople}>
					<SwitchComponents active={activeComponent}>
						<PeopleListView people={peopleResults} name="PeopleListView" path={location.pathname} />
						<PeopleGridView people={peopleResults} name="PeopleGridView" path={location.pathname} />
					</SwitchComponents>

					{!filtered && !searched &&
					<div className={paginationWrapper}>
						<Pagination
							itemName={archiveTitle}
							currPage={currPage}
							offset={offset}
							itemsPerPage={postsPerPage}
							totalItems={totalItems}
							numPages={numPages}
							previousPagePath={previousPagePath}
							nextPagePath={nextPagePath}
							archivePath={archivePath}
							passState={{ activeComponent: activeComponent }}
						/>
					</div>
					}
				</section>
			</Container>
		</Layout>
	)
}

export default PeoplePage

export const pageQuery = graphql`
	query WordPressPeopleArchive($offset: Int!, $postsPerPage: Int!) {
		allWpPeople(
			sort: { fields: [peopleInformation___lastName], order: ASC }
			limit: $postsPerPage
			skip: $offset
			filter: {locale: {id: {eq: "en_US"}}}
		) {
			edges {
				people: node {
					id
					uri
					slug
					title
					content
					excerpt
					jobTitles {
						nodes {
							id
							name
						}
					}
					peopleInformation {
						email
						fax
						fieldGroupName
						firstName
						lastName
						linkedin
						mobile
						phone
						twitter
						proOffice {
							... on WpOffice {
								title
								slug
								officeInformation {
									proOfficeCity
									proOfficeState
									proOfficeStreet
									proOfficeZipcode
								}
							}
						}
						proService {
							... on WpService {
								slug
								title
							}
						}
					}
					featuredImage {
						node {
							localFile {
								childImageSharp {
									gatsbyImageData(
										layout: CONSTRAINED
										aspectRatio: 0.87
										width: 300
									)
								}
							}
						}
					}
					locale {
						id
						locale
					}
					translations {
						id
						locale
						href
					}
					contentType {
						node {
							name
						}
					}
				}
			}
		}
		wp {
			seo {
				contentTypes {
					people {
						seo: archive {
							fullHead
							archiveLink
							breadcrumbTitle
							hasArchive
							metaDesc
							metaRobotsNoindex
							title
						}
					}
				}
			}
		}
	}
`
