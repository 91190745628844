// extracted by mini-css-extract-plugin
export var btnCircle = "PeopleListItem-module--btnCircle--35fI2";
export var lastItem = "PeopleListItem-module--last-item--d_SVJ";
export var listItemRow = "PeopleListItem-module--listItemRow--3suoD";
export var btnSmall = "PeopleListItem-module--btnSmall--3IDH7";
export var heading = "PeopleListItem-module--heading--1O8dm";
export var headshotWrapper = "PeopleListItem-module--headshot-wrapper--2YCGj";
export var nameWrapper = "PeopleListItem-module--nameWrapper--2qOyx";
export var name = "PeopleListItem-module--name--2tqwH";
export var email = "PeopleListItem-module--email--2is8E";
export var keylineLeft = "PeopleListItem-module--keyline-left--1hjbo";
export var headshot = "PeopleListItem-module--headshot--1MxRl";