import React from 'react'
import {GatsbyImage, getImage, StaticImage} from 'gatsby-plugin-image'
import clsx from "clsx";
import { Row, Col } from 'react-bootstrap'
import Link from '../Link'
import IconBtn from '../Common/StyledButton/IconBtn'
import {
	listItemRow,
	lastItem,
	heading,
	headshotWrapper,
	nameWrapper,
	name,
	keylineLeft,
} from './PeopleListItem.module.scss'

const PeopleListItem = ({ last, people, ...props }) => {
	const headshot = people?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
	const image = headshot ? getImage(headshot) : null
	
	return (
		<>
<Row className={clsx(listItemRow, 'align-content-center', last ? lastItem : '' )}>
				<Col className="align-content-center" xs={10} md={6} lg={4}>
					<div className={heading}>
						<div className={headshotWrapper}>
							<Link
								aria-describedby={people.id}
								to={people.uri}
								state={{activeComponent: 'PeopleListView', path: props.path}}
								tabIndex={-1}
							>
								{image ? (
									<GatsbyImage
										image={image}
										alt={people.title}
									/>
								) : (
									<StaticImage
										src="../../images/logo-placeholder.png"
										style={{
											height: '100%',
										}}
										alt={people.title}
									/>
								)}
							</Link>
						</div>
						<div
							className={[
								nameWrapper,
								'd-md-flex',
								' align-items-center',
							].join(' ')}
						>
							<div className={name}>
								<h2 className="title--md">
									<Link to={people.uri} state={{activeComponent: 'PeopleListView', path: props.path}}>
										{`${people?.peopleInformation?.lastName}, ${people?.peopleInformation?.firstName}`}
									</Link>
								</h2>
								<span className="label-small">
									{people?.jobTitles?.nodes ? (
										<span className="d-block d-sm-inline-flex flex-wrap">
											{people?.jobTitles?.nodes.map((job, i, arr) => (
												<div key={job.id}>
													<p id={people.id}>{job.name}</p>
													{arr.length > i + 1 ? <span className="mx-1">&</span> : null}
												</div>
											))}
										</span>
									) : null}
									{people?.peopleInformation?.proOffice ? (
									<span className="d-block d-md-none">
										{people?.peopleInformation?.proOffice[0]?.officeInformation?.proOfficeCity?.trim()}
										, {people?.peopleInformation?.proOffice[0]?.officeInformation?.proOfficeState}
									</span>
									) : null}
								</span>
							</div>
						</div>
					</div>
				</Col>
				<Col className="d-none d-md-flex align-items-center" sm={4} lg={2}>
					{people?.peopleInformation?.proOffice ? (
					<div className={keylineLeft}>
						{people?.peopleInformation?.proOffice[0]?.officeInformation?.proOfficeCity?.trim()}
						, {people?.peopleInformation?.proOffice[0]?.officeInformation?.proOfficeState}
					</div>
					) : null}
				</Col>
				<Col
					className="article-meta d-none justify-items-center d-lg-flex justify-content-center flex-column"
					md={3}
					lg={4}
				>
					<div className={keylineLeft}>

						{people?.peopleInformation?.email && (
							<IconBtn href={`mailto:${people?.peopleInformation?.email}`} variant="SendEmail"/>
						)}

						<div>
							<a href={'tel:' + people?.peopleInformation?.phone}>
								{people?.peopleInformation?.phone}
							</a>
						</div>
					</div>
				</Col>
				<Col
					className="d-flex align-items-center justify-content-end text-right"
					xs={2}
				>
					<IconBtn label="View Profile" href={people.uri} variant="IconRight" state={{activeComponent: 'PeopleListView', path: props.path}} />
				</Col>
			</Row>
		</>
	)
}

export default props => <PeopleListItem {...props} />
