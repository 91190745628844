import React from "react"
import PeopleListItem from "./PeopleListItem";
import PeopleListQuery from "./query/PeopleListQuery";

const PeopleListView = ({people, service, ...props}) => {

    const peopleData = people ?? PeopleListQuery(service)
    
    if (!service && !people?.length) {
        return (<p className="pt-5" role="status">No results found.</p>)
    }

    return (
        <>
            <p role="status" className="sr-only">{peopleData?.length} results found.</p>

            {peopleData?.map((v, i) => {
                let people = v.people
                
                return (
                    <PeopleListItem key={i} people={people} path={props.path} last={peopleData.length - 1 === i} />
                    
                )
            })}
        </>
    )
}

export default PeopleListView
