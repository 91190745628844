import { useStaticQuery, graphql } from 'gatsby'

export const usePeopleSearch = () => {
  const { allWpLanguage, allWpPeople, allWpOffice } = useStaticQuery(
    graphql`
        query AllPeopleSearch {
            allWpLanguage {
                edges {
                    language: node {
                        slug
                        name
                        allpeople {
                            nodes {
                                id
                            }
                        }
                    }
                }
            }
            allWpOffice {
                edges {
                    office: node {
                        title
                        slug
                        officeInformation {
                            proOfficeCity
                            proOfficeState
                        }
                    }
                }
            }
            allWpPeople(
                sort: { fields: [peopleInformation___lastName], order: ASC }
                filter: {locale: {id: {eq: "en_US"}}}
            ) {
                edges {
                    people: node {
                        id
                        uri
                        slug
                        title
                        content
                        excerpt
                        jobTitles {
                            nodes {
                                name
                                id
                            }
                        }
                        languages {
                            nodes {
                                name
                            }
                        }
                        peopleContent {
                            summary
                            education
                            repMatters
                        }
                        peopleInformation {
                            email
                            fax
                            fieldGroupName
                            firstName
                            lastName
                            linkedin
                            mobile
                            phone
                            twitter
                            proOffice {
                                ... on WpOffice {
                                    title
                                    slug
                                    officeInformation {
                                        proOfficeCity
                                        proOfficeState
                                        proOfficeStreet
                                        proOfficeZipcode
                                    }
                                }
                            }
                            proService {
                                ... on WpService {
                                    slug
                                    title
                                }
                            }
                        }
                        featuredImage {
                            node {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            layout: CONSTRAINED,
                                            aspectRatio: 0.87,
                                            width: 300
                                        )
                                    }
                                }
                            }
                        }
                        locale {
                            id
                            locale
                        }
                        translations {
                            id
                            locale
                            href
                        }
                        contentType {
                            node {
                                name
                            }
                        }
                    }
                }
            }
        }
    `
  )
  return {languages: allWpLanguage.edges, people: allWpPeople.edges, offices: allWpOffice.edges}
}